import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 640.000000 640.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)">
					<path d="M2936 4838 c-77 -133 -194 -334 -226 -393 -16 -27 -44 -77 -64 -109
-20 -33 -38 -67 -41 -77 -5 -16 78 -170 254 -467 17 -29 31 -59 31 -66 0 -11
-122 -230 -136 -245 -9 -10 -37 33 -165 257 -73 127 -137 232 -143 232 -6 0
-29 -33 -52 -73 -40 -71 -147 -257 -404 -702 -399 -693 -380 -656 -380 -718
l0 -57 419 0 420 0 44 78 c196 345 248 432 256 432 3 0 57 -90 119 -200 63
-110 121 -204 130 -209 24 -13 88 -11 106 3 8 6 24 29 36 51 84 153 203 355
209 355 4 0 26 -33 48 -72 64 -117 234 -412 245 -426 8 -9 101 -12 427 -10
l416 3 3 49 c3 52 27 7 -340 646 -52 91 -106 185 -120 210 -14 25 -92 160
-173 300 -81 140 -157 273 -170 295 -12 21 -27 41 -33 43 -10 3 -76 -104 -221
-360 -40 -71 -77 -128 -82 -128 -4 0 -40 54 -79 120 l-71 121 36 67 c20 37 61
108 90 157 29 50 80 137 113 195 l60 105 -27 50 c-15 28 -90 158 -166 290 -76
132 -152 264 -168 293 l-29 52 -58 0 -59 0 -55 -92z m140 -380 c9 -18 39 -69
65 -114 27 -45 49 -88 49 -96 0 -23 -133 -248 -145 -244 -10 3 -68 100 -120
199 l-25 47 20 38 c22 39 59 102 97 165 12 20 27 37 32 37 6 0 18 -15 27 -32z
m-547 -1140 c34 -58 61 -112 61 -119 0 -7 -13 -33 -29 -58 -16 -25 -77 -131
-136 -236 -60 -104 -115 -198 -123 -207 -13 -16 -34 -18 -152 -18 -75 0 -139
3 -143 6 -3 3 8 29 25 57 40 68 290 503 341 592 66 118 67 119 82 104 7 -8 40
-62 74 -121z m591 22 c34 -61 64 -119 67 -130 3 -12 -20 -62 -62 -135 -37 -63
-70 -117 -74 -119 -7 -5 -151 235 -151 252 0 5 13 28 29 53 15 24 47 77 70
117 23 39 46 72 50 72 5 0 36 -50 71 -110z m567 58 c16 -29 101 -177 188 -328
204 -353 215 -373 209 -382 -3 -4 -68 -8 -144 -8 -134 0 -140 1 -153 23 -12
18 -214 371 -274 477 -13 23 -11 30 30 100 24 41 56 96 71 123 15 26 31 47 36
47 5 0 21 -24 37 -52z"/>
					<path d="M2438 2293 c-11 -3 -18 -14 -18 -28 0 -13 -4 -35 -10 -49 -5 -14 -6
-26 -2 -26 4 0 13 14 19 30 6 17 16 30 21 30 10 0 24 -37 27 -72 4 -33 15 -48
36 -48 16 0 20 4 16 18 -4 9 -15 46 -25 82 -19 64 -28 73 -64 63z"/>
					<path d="M2990 2278 c0 -20 -31 -91 -42 -96 -13 -6 -9 -52 5 -52 16 0 49 38
42 50 -7 11 12 60 24 60 5 0 12 -17 16 -37 10 -53 22 -73 46 -73 23 0 23 -3
-11 98 -22 68 -26 72 -52 70 -19 -2 -28 -8 -28 -20z"/>
					<path d="M3432 2293 c-12 -2 -20 -8 -17 -13 8 -12 -26 -93 -43 -104 -12 -7
-12 -10 -1 -17 10 -6 11 -9 2 -9 -7 0 -13 -4 -13 -10 0 -5 6 -10 13 -10 15 0
32 30 41 70 14 62 39 64 51 3 9 -51 22 -73 43 -72 10 0 12 3 5 6 -7 2 -13 11
-13 18 0 7 -6 16 -12 18 -10 4 -10 6 0 6 6 1 12 9 12 19 0 13 -2 14 -9 3 -7
-11 -10 -11 -17 0 -4 7 -3 14 4 17 7 2 12 8 12 13 0 6 -5 7 -12 3 -7 -4 -8 -3
-4 5 9 14 -1 62 -12 60 -4 -1 -17 -4 -30 -6z"/>
					<path d="M3748 2293 c-27 -7 -21 -29 7 -29 14 0 25 6 25 12 0 16 -10 22 -32
17z"/>
					<path d="M4312 2292 c-20 -2 -32 -8 -29 -15 3 -7 11 -10 18 -8 28 8 82 2 89
-9 5 -9 9 -9 14 -1 16 25 -24 39 -92 33z"/>
					<path d="M1608 2210 l4 -80 53 0 c35 0 59 5 69 16 26 25 18 54 -18 68 -19 7
-19 6 -2 -13 17 -19 17 -20 -3 -40 -30 -30 -67 -15 -65 27 1 23 -2 29 -12 25
-12 -4 -13 3 -8 36 5 31 3 41 -7 41 -11 0 -13 -17 -11 -80z"/>
					<path d="M2560 2236 c0 -43 5 -60 23 -80 12 -14 29 -26 37 -27 40 -4 68 3 89
22 19 17 21 29 19 77 -3 48 -6 57 -23 60 -18 3 -20 -2 -17 -60 3 -50 1 -64
-14 -75 -45 -33 -74 -2 -74 78 0 52 -2 59 -20 59 -18 0 -20 -7 -20 -54z"/>
					<path d="M2768 2238 c-5 -79 1 -108 23 -108 12 0 19 7 19 20 0 28 24 25 44 -5
17 -26 46 -34 46 -12 0 7 -5 18 -12 25 -9 9 -8 16 5 30 24 26 22 79 -2 92 -11
5 -42 10 -69 10 l-50 0 -4 -52z m86 26 c19 -7 21 -45 4 -62 -18 -18 -48 -15
-50 6 0 9 -2 24 -3 32 -3 29 14 38 49 24z"/>
					<path d="M3192 2213 l3 -78 50 -3 c40 -2 55 1 73 18 29 28 38 66 21 97 -17 31
-46 43 -105 43 l-45 0 3 -77z m100 40 c36 -33 16 -100 -32 -105 -24 -3 -25 -1
-28 60 -3 58 -2 62 19 62 12 0 31 -7 41 -17z"/>
					<path d="M3540 2279 c0 -12 118 -10 126 3 3 4 -24 8 -60 8 -40 0 -66 -4 -66
-11z"/>
					<path d="M3933 2276 c-9 -11 -2 -13 40 -10 28 1 53 7 55 13 5 16 -81 13 -95
-3z"/>
					<path d="M4080 2275 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
					<path d="M4200 2280 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
					<path d="M3590 2249 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
					<path d="M2211 2164 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2385 2172 c-9 -10 -5 -42 6 -42 5 0 8 4 5 9 -3 5 1 11 9 15 8 3 15
9 15 14 0 9 -27 12 -35 4z"/>
					<path d="M2290 2155 c0 -9 6 -12 15 -9 8 4 15 7 15 9 0 2 -7 5 -15 9 -9 3 -15
0 -15 -9z"/>
					<path d="M1790 2140 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M3590 2140 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
					<path d="M2318 2133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
